.product_type_box {
  width: 100%;
  border-radius: 8px;
  box-shadow: 0 1px 2px 0 rgba(33, 37, 40, 0.1);
  border: solid 0.5px #dee2e5;
  background-color: #ffffff;
  padding: 1.25rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 0.625rem;
}

.product_type_box_title {
  font-size: 1.5rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.15px;
  color: #212528;
}

.product_type_box_text {
  text-align: left;
  font-size: 0.875rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 0.16px;
  color: #868d94;
  margin-top: 0.125rem;
  margin-bottom: 0.906rem;
}
