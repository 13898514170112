.networktype_container {
  background-image: linear-gradient(to bottom, #0d1921, #0c2d50);
  padding: 1.25rem;
  display: flex;
  flex-direction: column;
  height: auto;
}

.networktype_title {
  width: auto;
  height: auto;
  font-size: 1.5rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.15px;
  text-align: center;
  color: #ffffff;
  margin-top: 1.25rem;
}

.networktype_text {
  width: auto;
  height: auto;
  font-size: 1.063rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.17px;
  text-align: center;
  color: #ffffff;
  margin-top: 1.25rem;
}

.networktype_main {
  flex: 1;
}

.guide_text {
  font-size: 0.875rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 0.16px;
  text-align: center;
  color: #27b4eb;
  margin-left: 0.5rem;
}

.copyright_text {
  margin-top: 2.5rem;
  font-size: 0.563rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: -0.08px;
  text-align: center;
  color: #868d94;
  margin-bottom: 2.65rem;
}
