.need_box_list_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.need_box_list {
  width: 100%;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.need_box_list_title {
  font-size: 1.5rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.15px;
  color: #212528;
  margin: 2.5rem 1.25rem 1rem 1.25rem;
}
