.card {
  flex-direction: column;
  background-color: white;
  padding: 1rem 1.25rem 1rem 1.25rem;
  margin: 0.625rem 0.625rem 0.625rem 0.625rem;
  border-radius: 12px;
  border: solid 0.5px #dee2e5;
  display: flex;
  flex: 1;
  overflow: auto;
}
.card_title {
  margin: 0;
  font-size: 1.25rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.14px;
  color: #212528;
  text-align: left;
}

.card_desc {
  text-align: left;
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.15px;
  color: #343a3e;
}

.card_img_container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card_img {
  z-index: 2;
  width: 100%;
  height: 100%;
  max-height: calc(100vh - 25rem);
  object-fit: contain;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.card_progress_container {
  z-index: 1;
  position: absolute;
}

.card_hidden {
  display: none;
}
