.dialog_container {
  display: inline-flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  margin-top: 1.25rem;
}

.dialog_guide {
  width: 1rem;
  height: 1rem;
}

.dialog_title {
  text-align: start;
  margin: 0 0 0 0.5rem;
  font-size: 0.875rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: 0.16px;
  color: #27b4eb;
}

.dialog_pop_container {
  max-width: 420px;
  margin: auto;
}

.dialog_pop_content {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  padding-bottom: 0.5rem;
  border: solid 0.5px #dee2e5;
}

.dialog_pop_title {
  font-size: 1.063rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.17px;
  text-align: center;
  color: #212528;
}

.dialog_pop_desc {
  font-size: 0.875rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 0.16px;
  text-align: left;
  color: #495056;
}

.dialog_pop_button_container {
  display: inline-flex;
}

.dialog_pop_close {
  cursor: pointer;
  width: 50%;
  font-size: 1rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: 0.17px;
  text-align: center;
  color: #27b4eb;
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
}

.networktype_box {
  cursor: pointer;
  width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 1px 2px 0 rgba(33, 37, 40, 0.1);
  border: solid 0.5px #dee2e5;
  background-color: #ffffff;
  padding: 1.25rem;
  display: inline-flex;
  align-items: center;
  margin-top: 1.25rem;
  justify-content: space-between;
}

.networktype_box_item {
  display: flex;
  align-items: center;
}

.networktype_box_item_img {
  width: 2.75rem;
  height: 2.7rem;
}

.networktype_box_text {
  font-size: 1.5rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.15px;
  text-align: center;
  color: #212528;
  margin-left: 0.5rem;
}

.networktype_box_next {
  width: 1.25rem;
  height: 1.25rem;
}
