.main {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.content {
  flex-direction: column;
  display: flex;
  flex: 1;
}

.step_container {
  display: grid;
  width: 100%;
  height: 0.375rem;
  background-color: #0c2d50;
}

.step_item {
  opacity: 0.25;
  background-color: #ffffff;
  margin: 0.062rem;
}

.step_item_on {
  background-color: #27b4eb;
  margin: 0.062rem;
}
