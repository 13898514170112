.need_box {
  border-radius: 4px;
  border: solid 0.5px #dee2e5;
  background-color: #ffffff;
  padding-right: 1rem;
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
}

.need_box_icon {
  width: 1.5rem;
  height: 1.5rem;
  margin: 1rem;
}

.need_box_text_container {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  justify-content: flex-start;
  align-items: flex-start;
}

.need_box_title {
  text-align: left;
  width: fit-content;
  font-size: 1rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: 0.17px;
  color: #343a3e;
}

.need_box_desc {
  text-align: left;
  width: fit-content;
  font-size: 0.75rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.16px;
  color: #868d94;
}
