.intro_toolbar {
  background-image: linear-gradient(to bottom, #0d1921, #0c2d50);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1.25rem;
  position: fixed;
  width: 100%;
  max-width: 420px;
}

.intro_toolbar_back {
  cursor: pointer;
  width: 1.5rem;
  height: 1.5rem;
}

.intro_toolbar_header {
  width: 100%;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
}

.intro_toolbar_header_title {
  margin: auto;
  font-size: 1.063rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.17px;
  text-align: center;
  color: #ffffff;
  padding-right: 0.75rem;
}

.intro_toolbar_header_next {
  font-size: 1rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: 0.17px;
  text-align: right;
  color: #ffffff;
}

.intro_toolbar_text {
  font-size: 1.5rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.15px;
  color: #ffffff;
  margin-top: 2rem;
  margin-bottom: 0.625rem;
}
