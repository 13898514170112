.guide_container {
  display: flex;
}

.intro_link_button {
  margin-top: 1.25rem;
  width: 100%;
  border-radius: 4px;
  background-color: #ffffff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.intro_link_button_text {
  cursor: pointer;
  width: auto;
  height: auto;
  font-size: 0.938rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.15px;
  text-align: center;
  color: #27b4eb;
}

.guide_img {
  width: 1rem;
  height: 1rem;
}

.guide_title {
  text-align: start;
  margin: 0;
  margin-left: 0.5rem;
  font-size: 0.875rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: 0.16px;
  color: #27b4eb;
}

.guide_pop_container {
  max-width: 420px;
  margin: auto;
}

.guide_pop_content {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.guide_pop_img {
  width: 100%;
  max-height: 25vh;
  object-fit: contain;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.guide_pop_title {
  margin-top: 1.5rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  font-size: 1.063rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.17px;
  text-align: center;
  color: #212528;
}

.guide_pop_desc {
  font-size: 0.875rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 0.16px;
  text-align: center;
  color: #495056;
}

.guide_pop_close {
  cursor: pointer;
  font-size: 1rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: 0.17px;
  text-align: center;
  color: #27b4eb;
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
  border-top: solid 0.5px #dee2e5;
  margin-block-start: 0;
  margin-block-end: 0;
  padding: 0.5rem;
}

.complete_down_button {
  width: 100%;
  height: 3.75rem;
  border-radius: 8px;
  background-color: #27b4eb;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.complete_down_text {
  font-size: 1.063rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  margin-right: 0.25rem;
  line-height: normal;
  letter-spacing: 0.17px;
  color: #ffffff;
}

.complete_down_next {
  width: 1.25rem;
  height: 1.25rem;
}
