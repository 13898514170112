.parts_header {
  width: 100%;
  height: fit-content;
  background-image: linear-gradient(to bottom, #0d1921, #0c2d50);
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 1.25rem;
  padding-right: 2.75rem;
  padding-top: 1.125rem;
  padding-bottom: 1.125rem;
}

.parts_header_title {
  width: fit-content;
  font-size: 1.063rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.17px;
  text-align: center;
  color: #ffffff;
}
