.choice {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.choice_title {
  margin: 0;
  font-size: 1.25rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.14px;
  color: #212528;
  text-align: center;
}

.choice_desc {
  margin-left: 1.125rem;
  margin-right: 1.125rem;
  text-align: center;
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.15px;
  color: #343a3e;
}

.choice_container {
  position: relative;
  margin-top: 1.5rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.choice_card {
  cursor: pointer;
  position: relative;
  width: 45%;
  height: 15.25rem;
  flex-direction: column;
  padding: 0.75rem 0.75rem 1.25rem 0.75rem;
  margin: 0rem 0.3rem;
  display: flex;
  border-radius: 8.5px;
  box-shadow: 0 1px 2px 0 rgba(33, 37, 40, 0.1);
  border: solid 0.5px #dee2e5;
  background-color: #ffffff;
  color: #212528;
}

.choice_card_on {
  cursor: pointer;
  box-shadow: 0 1px 2px 0 rgba(33, 37, 40, 0.1);
  border: solid 1px #27b4eb;
  background-color: #f9fdff;
  display: flex;
  color: #27b4eb;
}

#choice1_img_container {
  margin-right: 0.15rem;
}

#choice2_img_container {
  margin-left: 0.15rem;
}

.choice_img {
  position: relative;
  width: 100%;
  height: 10rem;
  object-fit: contain;
}

.choice_name_wrapper {
  margin-top: 0.75rem;
  text-align: center;
  display: table;
  margin: auto;
}

.choice_name {
  display: table-cell;
  vertical-align: middle;
  font-size: 1.063rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.17px;
  text-align: center;
}
