.networktype_box {
  width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 1px 2px 0 rgba(33, 37, 40, 0.1);
  border: solid 0.5px #dee2e5;
  background-color: #ffffff;
  padding: 1.25rem;
  display: inline-flex;
  align-items: center;
  margin-top: 1.25rem;
  justify-content: space-between;
}

.networktype_box_item {
  display: flex;
  align-items: center;
}

.networktype_box_item_img {
  width: 2.75rem;
  height: 2.7rem;
}

.networktype_box_text {
  font-size: 1.5rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.15px;
  text-align: start;
  color: #212528;
  margin-left: 0.5rem;
}

.networktype_box_next {
  width: 1.25rem;
  height: 1.25rem;
}
