.footer {
  display: flex;
  flex: none;
  width: 100%;
  height: 3.875rem;
}

.footer_background {
  display: flex;
  width: 100%;
  height: 100%;
  margin-right: 0.625rem;
  margin-left: 0.625rem;
  flex-direction: row;
}

.footer_list {
  cursor: pointer;
  display: flex;
  min-width: 3.25rem;
  height: 3.25rem;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgba(33, 37, 40, 0.1);
  border: solid 1px #27b4eb;
  background-color: #f9fdff;
}

.footer_list_img {
  width: 1.5rem;
  height: 1.5rem;
}

.footer_next_button_on {
  margin-left: 0.5rem;
  width: 100%;
  height: 3.25rem;
  border-radius: 8px;
  background-color: #27b4eb;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}

.footer_next_button {
  cursor: unset;
  margin-left: 0.5rem;
  width: 100%;
  height: 3.25rem;
  border-radius: 8px;
  background-color: #92daf4;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}

.footer_next_button_text {
  font-size: 1.063rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  margin-right: 0.25rem;
  line-height: normal;
  letter-spacing: 0.17px;
  color: #ffffff;
}

.footer_next_button_next {
  width: 1.25rem;
  height: 1.25rem;
}
