.faqlink_container {
  cursor: pointer;
  display: flex;
  padding-bottom: 8px;
}

.faqlink_img {
  width: 1rem;
  height: 1rem;
}

.faqlink_title {
  text-align: start;
  margin: 0;
  margin-left: 0.5rem;
  font-size: 0.875rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: 0.16px;
  color: #27b4eb;
}
