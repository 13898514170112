.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

.App {
  text-align: center;
  height: 100%;
}

html {
  font-size: 100%;
}

html,
body {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: auto;
  background-color: #edf0f1;
  display: flex;
  justify-content: center;
}

a {
  text-decoration: none;
}

#root {
  max-width: 420px;
  max-height: 840px;
  margin: auto;
  height: 100%;
  width: 100%;
  background: #f6f6f6;
}

.container {
  flex-direction: column;
  display: flex;
  flex: 1;
  background: lightgray;
  padding: 1rem;
  font-size: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.image_100 {
  width: 100%;
}

.flex_1 {
  flex: 1;
}

.background_flex {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.padding_1_25 {
  padding: 1.25rem;
}

.side_padding_1_25 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.MuiDialog-paper {
  margin: 0;
  position: relative;
  overflow-y: auto;
}

.MuiDialog-root {
  max-width: 420px;
  max-height: 840px;
  margin: auto;
  height: 100%;
  width: 100%;
}
