.guide_container {
  cursor: pointer;
  display: flex;
}

.guide_img {
  width: 1rem;
  height: 1rem;
}

.guide_title {
  text-align: start;
  margin: 0;
  margin-left: 0.5rem;
  font-size: 0.875rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: 0.16px;
  color: #27b4eb;
}

.guide_pop_container {
  max-width: 420px;
  margin: auto;
}

.guide_pop_content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 1.25rem;
  border: solid 0.5px #dee2e5;
}

.guide_pop_img {
  width: 12.5rem;
  height: 12.5rem;
  object-fit: contain;
  z-index: 2;
}

.guide_pop_progress {
  display: flex;
  width: 12.5rem;
  height: 12.5rem;
  position: absolute;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.guide_pop_img_container {
  display: flex;
  margin-bottom: 0.75rem;
}

.guide_pop_img_empty {
  display: none;
}

.guide_pop_title {
  margin: 0;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  font-size: 1.063rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.17px;
  text-align: center;
  color: #212528;
}

.guide_pop_desc {
  margin: 1rem 0 0 0;
  font-size: 0.875rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 0.16px;
  text-align: center;
  color: #495056;
}

.guide_pop_close {
  cursor: pointer;
  font-size: 1rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: 0.17px;
  text-align: center;
  color: #27b4eb;
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
}
