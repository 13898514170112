.head_container {
  width: 100%;
  height: 3.75rem;
  background-color: #edf0f1;
  display: flex;
  flex-direction: column;
}

.head_toolbar {
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to bottom, #0d1921, #0c2d50);
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  padding-top: 1.125rem;
  padding-bottom: 1.125rem;
}

.head_toolbar_back {
  cursor: pointer;
  width: 1.5rem;
  height: 1.5rem;
}

.head_toolbar_title {
  font-size: 1.063rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.17px;
  text-align: center;
  color: #ffffff;
}
.head_toolbar_title_img {
  width: 7.156rem;
  object-fit: contain;
}
.head_toolbar_menu {
  cursor: pointer;
  font-size: 1rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: 0.17px;
  text-align: right;
  color: #ffffff;
  padding-top: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
}
