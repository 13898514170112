.parts_type {
  width: 100%;
  margin-top: 1.625rem;
  display: inline-flex;
  grid-column-start: 1;
  grid-column-end: span 2;
  align-items: center;
  margin-bottom: 0.625rem;
}

.parts_type_img_container {
  width: 3.125rem;
  height: 3.125rem;
}
.parts_type_img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.parts_type_text {
  font-size: 1.5rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.15px;
  color: #212528;
  margin-left: 1rem;
}

.parts_item {
  height: 10.188rem;
  border-radius: 8px;
  border: solid 0.5px #dee2e5;
  background-color: #ffffff;
}
.parts_item_container {
  width: auto;
  height: 6.938rem;
  padding: 0.5rem 1.609rem 0.25rem;
}
.parts_item_img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.parts_item_text_container {
  width: 100%;
  height: 2rem;
  margin-top: 0.25rem;
  margin-bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.parts_item_text {
  font-size: 0.75rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -0.1px;
  text-align: center;
  color: #495056;
}
