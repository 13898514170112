.azure_solid_button {
  width: 100%;
  border-radius: 4px;
  background-color: #27b4eb;
  font-size: 1.063rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.17px;
  text-align: center;
  color: #ffffff;
  margin-top: 2rem;
  margin-bottom: 2.5rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
