.intro_body {
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #ced3d8;
}

.intro_container {
  width: 100%;
  height: fit-content;
  background-image: linear-gradient(to bottom, #0d1921, #0c2d50);
  padding: 1.25rem;
}

.intro_footer {
  width: 100%;
  height: fit-content;
  background-image: linear-gradient(to bottom, #0d1921, #0c2d50);
  padding-top: 1.625rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  padding-bottom: 3.719rem;
  flex: 1;
}

.logo {
  cursor: pointer;
  width: 7.312rem;
  height: 1.5rem;
}

.title {
  font-size: 1.5rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.15px;
  text-align: center;
  color: #ffffff;
  margin-top: 1.25rem;
}

.intro_footer_text {
  font-size: 1.063rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.17px;
  text-align: center;
  color: #ffffff;
  margin-top: 0.75rem;
}

.intro_link_container {
  background-image: linear-gradient(to bottom, #dee2e5, #acb4bb);
  text-align: left;
  display: flex;
}

.intro_link_container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.intro_link_img {
  width: 45%;
}

.intro_link_desc_container {
  width: 55%;
  padding-left: 1.75rem;
  margin-right: 1.25rem;
}

.intro_link_title {
  font-size: 1rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: 0.17px;
  color: #212528;
  margin: auto;
}

.intro_link_button {
  margin-top: 1.25rem;
  width: 100%;
  border-radius: 4px;
  background-color: #ffffff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.intro_link_button_text {
  cursor: pointer;
  width: auto;
  height: auto;
  font-size: 0.938rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.15px;
  text-align: center;
  color: #27b4eb;
}

.intro_copyright {
  margin: auto;
  background-color: #ced3d8;
}

.intro_copyright {
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-size: 0.563rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: -0.08px;
  text-align: center;
  color: #868d94;
}
