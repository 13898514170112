.intro_image_container {
  margin-top: 0.75rem;
}

.intro_button {
  width: 100%;
  height: 3.75rem;
  border-radius: 8px;
  background-color: #27b4eb;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.intro_button_text {
  font-size: 1.063rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  margin-right: 0.25rem;
  line-height: normal;
  letter-spacing: 0.17px;
  color: #ffffff;
}

.intro_button_next {
  width: 1.25rem;
  height: 1.25rem;
}

.intro_info_container {
  display: inline-flex;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  margin-top: 1.25rem;
}

.intro_info_img {
  width: 1.25rem;
  height: 1.25rem;
}

.intro_image {
  width: 100%;
}

.intro_info {
  font-size: 0.875rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  text-align: start;
  letter-spacing: 0.16px;
  color: #dee2e5;
  margin-left: 1rem;
}
