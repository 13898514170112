.complete_body {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.complete_container {
  width: 100%;
  background-image: linear-gradient(to bottom, #0d1921, #0c2d50);
  padding: 1.25rem;
}

.complete_footer {
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to bottom, #0d1921, #0c2d50);
  padding: 1.25rem;
}

.complete_back_home {
  width: 100%;
  display: inline-flex;
  justify-content: center;
  margin-top: 1.875rem;
}

.complete_back_home_text {
  font-size: 1.063rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.17px;
  text-align: center;
  color: #27b4eb;
  margin-right: 0.5rem;
}

.complete_back_home_back {
  width: 1.25rem;
  height: 1.25rem;
}

.complete_copyright {
  font-size: 0.563rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: -0.08px;
  text-align: center;
  color: #868d94;
  margin-top: 2.375rem;
}

.complete_down_container {
  margin-top: 0.75rem;
}

.complete_down_image {
  margin-top: 1.5rem;
  width: 13.375rem;
  height: 12.688rem;
}

.complete_down_button {
  width: 100%;
  height: 3.75rem;
  border-radius: 8px;
  background-color: #27b4eb;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.complete_down_text {
  font-size: 1.063rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  margin-right: 0.25rem;
  line-height: normal;
  letter-spacing: 0.17px;
  color: #ffffff;
}

.complete_down_next {
  width: 1.25rem;
  height: 1.25rem;
}

.complete_info_container {
  display: inline-flex;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  margin-top: 1.25rem;
}

.complete_info_img {
  width: 1.25rem;
  height: 1.25rem;
}

.complete_info {
  font-size: 0.875rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  text-align: start;
  letter-spacing: 0.16px;
  color: #dee2e5;
  margin-left: 1rem;
}
