.product_package {
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.set_title {
  font-size: 1.5rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.15px;
  color: #212528;
  margin: 1.25rem 1.25rem 1rem 1.25rem;
}

.parts_title {
  font-size: 0.875rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 0.16px;
  color: #868d94;
  margin: 1rem 1.25rem 0.375rem 1.25rem;
}

.product_img {
  width: 100%;
  height: 13.68rem;
}
