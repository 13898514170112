.productType_container {
  display: flex;
}

.productTypeBoxList_container {
  width: 100%;
  height: auto;
  padding-top: 8.5rem;
  background-color: #f1f3f4;
  margin: auto;
}
