.Parts {
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #f1f3f4;
}

.toolbar_container {
  position: fixed;
  width: 100%;
  max-width: 420px;
}

.parts_container {
  padding-top: 3.75rem;
  height: auto;
  background-color: #f1f3f4;
}
