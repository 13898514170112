.parts_box {
  width: 100%;
  display: grid;
  gap: 0.625rem;
  padding: 0 1.25rem 2.5rem;
  /* padding-top: 0.625rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem; */
  grid-template-columns: 1fr 1fr;
}
