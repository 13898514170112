.list {
  width: 250;
}
.full_list {
  display: flex;
  flex-direction: column;
  width: auto;
  height: 100vh;
}

.toolbar_container {
  position: fixed;
  width: 100%;
  max-width: 420px;
  z-index: 2;
}

.menu_container {
  z-index: 1;
  padding-top: 3.75rem;
}

.nested {
  padding-left: 12px;
}

.list_index {
  width: 1.25rem;
  height: 1.25rem;
  line-height: 1.25rem;
  font-size: 0.75rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.16px;
  text-align: center;
}
.list_index_main {
  color: #495056;
}
.list_index_sub {
  margin-left: 1.812rem;
  line-height: 1.33rem;
  color: #868d94;
}
.list_index_selected {
  color: #27b4eb;
}

.list_item {
  height: 3rem;
  box-shadow: 20px 0.5px 0 0 #dee2e5;
  /* boxs 0 -0.5px 0 0 #dee2e5'; */
  background-color: #ffffff;
  padding: 0.875rem 0.625rem;
}
.list_item_selected {
  background-color: #eaf8fd !important;
}

.list_item_text {
  flex: 1 1 auto;
  min-width: 0;
  font-size: 1.063rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.17px;
  color: #212528;
}

.list_item_text_sub {
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.25;
  color: #343a3e;
}

.list_item_text_selected {
  color: #27b4eb;
}

.MuiList-subheader {
  padding-bottom: 0;
}
